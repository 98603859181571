import React, { useEffect, useState, useRef } from 'react';
import { Card, CardBody, CardHeader, CardFooter } from '@paljs/ui/Card';
import Col from '@paljs/ui/Col';
import Row from '@paljs/ui/Row';
import { Button } from '@paljs/ui/Button';
import { InputGroup } from '@paljs/ui/Input';
import { nodeDetails } from '../../actions/nodeDetailsAction';
import { Checkbox } from '@paljs/ui/Checkbox';
import EditDate from '../../components/date/editDate';
import { postEditUserData } from '../../actions/EditUserDatailsActions';
import LoadingSpinner from '../loader';
import { koot_falan } from '../../helpers/utils';
import { Toastr, ToastrRef, ToastrProps } from '@paljs/ui/Toastr';
import SelectGautra from './select-gautra';
import SelectVillage from './select-village';
import SelectName from './select-name';
import Modal from '../../Utilities/Modal';
import moment from 'moment';
import SelectSamajMitra from './select-samajMitra';
import MukhiyaWithSamajMitra from '../../components/mukhiyaSamajMitra/MukhiyaWithSamajMitra';
import FormatDisplayName from '../matrimonials/componentUtils/formatDisplayName';

const EditBasicDetails = ({ editName, node_id, onClose, updatePage, nodeDetailsToUpdate = null }) => {
  const [message, setMessage] = useState('');
  const [isLoading, setLoader] = useState(false);
  const [name, setName] = useState('');
  const [nickName, setNickName] = useState('');
  const [gender, setGender] = useState('male');
  const [gautraObj, setGautraObj] = useState();
  const [subCaste, setSubCaste] = useState('');
  const [pincode, setPincode] = useState('');
  const [village, setVillage] = useState('');
  const [alive, setAlive] = useState(true);
  const [married, setMarried] = useState(false);
  const [dateOfBirth, setDateOfBirth] = useState({});
  const [dateOfMarriage, setDateOfMarriage] = useState('');
  const [dateOfDeath, setDateOfDeath] = useState('');
  const [nodeId, setNodeId] = useState('');
  const [email, setEmail] = useState('');
  const [isMukhiya, setIsMukhiya] = useState(false);
  // const [samajMitra, setSamajMitra] = useState('');
  const [samajMitraId, setSamajMitraId] = useState(null);
  const [previousName, setPreviousName] = useState('');
  const [previousDateYear, setPreviousDateYear] = useState('');

  const [toastData, setToastData] = useState<ToastrProps>({
    position: 'topEnd',
    status: 'Success',
    duration: 2000,
    hasIcon: true,
    destroyByClick: true,
    preventDuplicates: false,
  });
  const toastrRef = useRef<ToastrRef>(null);

  useEffect(() => {
    detailsToBeEdited();
  }, []);

  const detailsToBeEdited = async () => {
    setMessage(`Loading data of ${editName}`);
    setLoader(true);
    try {
      const response = await nodeDetails(node_id);
      if (!response) {
        throw new Error('Error fetching node details');
      }
      const data = response.basicProfile;
      // Fetch samaj mitra details in parallel
      const samajMitraPromise = data?.samaj_mitra_graph_node_id
        ? nodeDetails(data.samaj_mitra_graph_node_id)
        : Promise.resolve(null);
      const samajMitraResponse = await samajMitraPromise;
      const samajMitraFetchedName = FormatDisplayName(
        samajMitraResponse?.basicProfile?.name,
        samajMitraResponse?.basicProfile?.gautra,
        samajMitraResponse?.basicProfile?.subcaste || '',
      );
      // const samajMitraFetchedName = samajMitraResponse?.basicProfile?.name || '';
      console.log('samajmitraName:', samajMitraFetchedName);
      // Updating state in a single batch
      setName(data.name);
      setNickName(data.nickName);
      setGender(data.gender);
      setGautraObj({
        gautra: data.gautra,
        subcaste: data.subcaste,
      });
      setSubCaste(data.subcaste);
      setPincode(data?.currentAddress?.pinCode);
      setSamajMitraId(data?.samaj_mitra_graph_node_id);
      // setSamajMitra(samajMitraFetchedName);
      setVillage(data.village);
      setAlive(data.alive);
      setMarried(data.married);
      setDateOfBirth(data.dateOfBirth);
      setDateOfDeath(data.dateOfDeath);
      setDateOfMarriage(data.dateOfMarriage);
      setNodeId(data.graph_node_id);
      setEmail(data.email);
      setIsMukhiya(data.is_mukhiya);

      toastrRef.current?.add('Details Fetched Successfully', 'Success', { ...toastData });
    } catch (error) {
      toastrRef.current?.add('Error Occurred', 'Error', { ...toastData, status: 'Danger' });
    } finally {
      setLoader(false);
    }
  };

  const onSubmit = async () => {
    const kootFalan = koot_falan(name);
    setMessage(`Updating details of ${name}`);
    const data = {
      name,
      nickName,
      gender,
      gautra: gautraObj?.gautra,
      subCaste: gautraObj?.subcaste ?? '',
      pincode,
      village,
      alive,
      married,
      dateOfBirth,
      dateOfMarriage,
      dateOfDeath,
      email,
      is_mukhiya: isMukhiya,
      graph_node_id: nodeId,
      samaj_mitra_graph_node_id: isMukhiya ? samajMitraId : null,
      koot_falan: kootFalan,
    };
    console.log('updated data', data);
    const response = await postEditUserData(data);
    if (response) {
      toastrRef.current?.add('Details Updated Successfully', 'Success', { ...toastData });
      onClose();
      updatePage();
    } else {
      toastrRef.current?.add('Error Occurred', 'Error', { ...toastData, status: 'Danger' });
    }
  };

  const onMarriageChange = (value) => setDateOfMarriage(value);
  const onBirthChange = (value) => setDateOfBirth(value);
  const onDeathChange = (value) => !alive && setDateOfDeath(value);

  useEffect(() => {
    if (alive) setDateOfDeath(''); // Reset dateOfDeath if alive is true
  }, [alive]);

  const editDetails = (
    <Modal toggle={onClose}>
      <Toastr ref={toastrRef} />
      <Card>
        {isLoading ? (
          <LoadingSpinner message={message} />
        ) : (
          <>
            <CardHeader>Edit Basic Details of {editName}</CardHeader>
            <CardBody>
              <Row style={{ display: 'flex', flexWrap: 'nowrap', flexDirection: 'column' }}>
                <div style={{ color: 'red', border: '1px solid ' }}>
                  नाम के आगे श्री/स्व/श्रीमती और अंत में जी, कूमार, कुमारी, देवी, गौत्र, समाज नाम इत्यादि न लगाएँ, केवल
                  मूल नाम लिखें{' '}
                </div>

                <Col style={{ margin: '10px', justifyContent: 'center', flexWrap: 'wrap' }}>
                  <InputGroup style={{ width: '300px', padding: '5px' }} fullWidth>
                    <div>
                      Name:
                      {nodeDetailsToUpdate && name !== nodeDetailsToUpdate?.name ? (
                        <span
                          style={{ color: 'blue', cursor: 'pointer' }}
                          onClick={() => {
                            setPreviousName(name);
                            setName(nodeDetailsToUpdate?.name);
                          }}
                        >
                          &nbsp;Update Name: {nodeDetailsToUpdate?.name}
                        </span>
                      ) : previousName ? (
                        <span
                          style={{ color: 'blue', cursor: 'pointer' }}
                          onClick={() => {
                            setName(previousName);
                            setPreviousName('');
                          }}
                        >
                          &nbsp;Previous Name: {previousName}
                        </span>
                      ) : null}
                    </div>
                    <SelectName setName={setName} gender={gender} name={name} />
                  </InputGroup>

                  <InputGroup style={{ width: '300px', padding: '5px' }} fullWidth>
                    <div>Nick Name:</div>
                    <input
                      placeholder="Nick Name"
                      value={nickName}
                      onChange={(event) => setNickName(event.target.value)}
                    />
                  </InputGroup>
                </Col>

                <Col style={{ margin: '10px', justifyContent: 'center', flexWrap: 'wrap' }}>
                  <div style={{ width: '300px', padding: '5px' }}>
                    Gautra:
                    <SelectGautra setGautraObj={setGautraObj} gautraObj={gautraObj} disabled />
                  </div>
                </Col>

                <Col
                  style={{
                    display: 'flex',
                    // alignItems: 'center',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    flexWrap: 'wrap',
                    gap: '10px',
                    marginLeft: '10px',
                  }}
                >
                  <MukhiyaWithSamajMitra
                    isMukhiya={isMukhiya}
                    setIsMukhiya={setIsMukhiya}
                    samajMitraId={samajMitraId}
                    setSamajMitra={(samajMitra) => {
                      if (!samajMitra) {
                        setSamajMitraId(null);
                      }
                      setSamajMitraId(samajMitra?.graphNodeId);

                      // setSamajMitraId(samajMitra && samajMitra?.graphNodeId ? samajMitra.graphNodeId : null);
                    }}
                  />
                  <Checkbox checked={alive} onChange={(value) => setAlive(value)}>
                    Alive
                  </Checkbox>
                  <Checkbox checked={married} onChange={(value) => setMarried(value)}>
                    Married
                  </Checkbox>
                </Col>

                {/* {isMukhiya && (
                  <div style={{ width: '300px', padding: '5px' }}>
                    Samaj Mitra:
                    <SelectSamajMitra
                      setSamajMitraId={setSamajMitraId}
                      setSamajMitra={setSamajMitra}
                      samajMitra={samajMitra}
                    />
                  </div>
                )} */}

                <Col style={{ margin: '10px', justifyContent: 'center', flexWrap: 'wrap' }}>
                  <InputGroup style={{ width: '400px', padding: '5px' }}>
                    Date Of Birth:
                    <EditDate defaultDate={dateOfBirth} onChange={onBirthChange} />
                    {nodeDetailsToUpdate && dateOfBirth?.year !== nodeDetailsToUpdate?.dateOfBirth?.year ? (
                      <span
                        style={{ color: 'blue', cursor: 'pointer' }}
                        onClick={() => {
                          setPreviousDateYear(dateOfBirth.year);
                          setDateOfBirth(nodeDetailsToUpdate?.dateOfBirth);
                        }}
                      >
                        &nbsp;Date: {moment(nodeDetailsToUpdate?.dateOfBirth).format('DD-MM-YYYY')}
                      </span>
                    ) : previousDateYear ? (
                      <span
                        style={{ color: 'blue', cursor: 'pointer' }}
                        onClick={() => {
                          setDateOfBirth({ ...dateOfBirth, year: previousDateYear });
                          setPreviousDateYear('');
                        }}
                      >
                        &nbsp;Previous Date: {previousDateYear}
                      </span>
                    ) : null}
                  </InputGroup>
                </Col>

                {married && (
                  <Col style={{ margin: '10px', justifyContent: 'center', flexWrap: 'wrap' }}>
                    <InputGroup style={{ width: '300px', padding: '5px' }}>
                      Date Of Marriage:
                      <EditDate defaultDate={dateOfMarriage} onChange={onMarriageChange} />
                    </InputGroup>
                  </Col>
                )}

                {!alive && (
                  <Col style={{ margin: '10px', justifyContent: 'center', flexWrap: 'wrap' }}>
                    <InputGroup style={{ width: '300px', padding: '5px' }}>
                      Date Of Death:
                      <EditDate defaultDate={dateOfDeath} onChange={onDeathChange} />
                    </InputGroup>
                  </Col>
                )}

                <Col style={{ margin: '10px', justifyContent: 'center', flexWrap: 'wrap' }}>
                  <InputGroup style={{ width: '300px', padding: '5px' }}>
                    Email:
                    <input placeholder="Email" value={email} onChange={(event) => setEmail(event.target.value)} />
                  </InputGroup>
                </Col>

                <Col style={{ margin: '10px', justifyContent: 'center', flexWrap: 'wrap' }}>
                  <InputGroup style={{ width: '300px', padding: '5px' }}>
                    Village:
                    <SelectVillage setVillage={setVillage} village={village} />
                  </InputGroup>
                </Col>
              </Row>
            </CardBody>
            <CardFooter>
              <Button onClick={onSubmit} style={{ marginRight: '10px' }}>
                Submit
              </Button>
              <Button onClick={onClose}>Cancel</Button>
            </CardFooter>
          </>
        )}
      </Card>
    </Modal>
  );

  return <>{editDetails}</>;
};

export default EditBasicDetails;
